export const JumpballStartGame = {
  name: "jumpball-start",
  component: "InitialJumpBall",
  layout: "jumpball-start main-panel x-large",
  mode: "visible",
  text: "jumpball.start",
  showOn: [{ state: "primary", value: "jumpball-start", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: false,
  options: {},
  children: [],
};
