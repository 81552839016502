import React, { useContext, useEffect, useState } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import ButtonPanel from "../ButtonPanel/ButtonPanel";
import Clock from "../Clock/Clock";
import MultiDeviceClock from "../MultiDeviceClock/MultiDeviceClock";
import DebugPanel from "../DebugPanel/DebugPanel";
import DeletePanel from "../DeletePanel/DeletePanel";
import DisplayClock from "../DisplayClock/DisplayClock";
import EditPanel from "../EditPanel/EditPanel";
import FieldButtons from "../FieldButtons/FieldButtons";
import InsertClock from "../InsertClock/InsertClock";
import InsertPanel from "../InsertPanel/InsertPanel";
import MainPanel from "../MainPanel/MainPanel";
import ModeSelector from "../ModeSelector/ModeSelector";
import NewPositionSelect from "../NewPositionSelect/NewPositionSelect";
import Players from "../Players/Players";
import SuspendedPlayers from "../Players/handball/SuspendedPlayers";
import PossessionConfirm from "../PossessionConfirm/PossessionConfirm";
import StackingPanel from "../StackingPanel/StackingPanel";
import TeamButtonGroup from "../TeamButtonGroup/TeamButtonGroup";
import TeamButtons from "../TeamButtons/TeamButtons";
import TeamGroupedButtons from "../TeamGroupedButton/TeamGroupedButtons";
import Teams from "../Teams/Teams";
import InitialJumpBall from "../StartMatch/Basketball/Jumpball/InitialJumpball";

const EmptyPanel = ({ panel }) => {
  return <div style={panel.customStyle} />;
};

export const buildComponentForPanel = (panel, props) => {
  switch (panel.component) {
    case "Clock":
      return <Clock {...props} panel={panel} />;
    case "MultiDeviceClock":
      return <MultiDeviceClock {...props} panel={panel} />;
    case "DisplayClock":
      return <DisplayClock {...props} panel={panel} />;
    case "InsertClock":
      return <InsertClock {...props} panel={panel} />;
    case "Teams":
      return <Teams {...props} panel={panel} />;
    case "Players":
      return <Players {...props} panel={panel} />;
    case "ButtonPanel":
      return <ButtonPanel {...props} panel={panel} />;
    case "TeamButtons":
      return <TeamButtons {...props} panel={panel} />;
    case "MainPanel":
      return <MainPanel {...props} panel={panel} />;
    case "EditPanel":
      return <EditPanel {...props} panel={panel} />;
    case "InsertPanel":
      return <InsertPanel {...props} panel={panel} />;
    case "DeletePanel":
      return <DeletePanel {...props} panel={panel} />;
    case "DebugPanel":
      return <DebugPanel {...props} panel={panel} />;
    case "PossessionConfirm":
      return <PossessionConfirm {...props} panel={panel} />;
    case "StackingPanel":
      return <StackingPanel {...props} panel={panel} />;
    case "EmptyPanel":
      return <EmptyPanel panel={panel} />;
    case "NewPositionSelect":
      return <NewPositionSelect {...props} panel={panel} />;
    case "TeamGroupedButtons":
      return <TeamGroupedButtons {...props} panel={panel} />;
    case "FieldButtons":
      return <FieldButtons {...props} panel={panel} />;
    case "ModeSelector":
      return <ModeSelector {...props} panel={panel} />;
    case "TeamButtonGroup":
      return <TeamButtonGroup {...props} panel={panel} />;
    case "SuspendedPlayers":
      return <SuspendedPlayers {...props} panel={panel} />;
    case "InitialJumpBall":
      return <InitialJumpBall {...props} panel={panel} />;
    default:
      return null;
  }
};

const WorkflowBuilder = (props) => {
  const { workFlow } = props;
  const [layout, setLayout] = useState();
  const { hasLoaded } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (workFlow && hasLoaded) {
      setLayout(
        workFlow.panels.map((panel, index) => (
          <React.Fragment key={index}>{buildComponentForPanel(panel, props)}</React.Fragment>
        )),
      );
    }
  }, [workFlow, hasLoaded, props]);

  return <React.Fragment>{layout}</React.Fragment>;
};

export default WorkflowBuilder;
