import { convertClock } from "../ConvertClock";
import { eventLogCatchup } from "../Catchup/EventLogCatchup";
import defaultSetup from "../../../../Config/defaultSetupConfiguration";
import Logger from "../Logger";

const logger = Logger.getInstance();

export async function processCatchup(
  message,
  currentState,
  updateState,
  manageEvents,
  managePlays,
  fixtureId,
  fixtureProfile,
  fromLive = false,
) {
  let scoreTypes = defaultSetup.scoreTypes;
  let scoreSubTypes = defaultSetup.scoreSubTypes;

  updateState("processing", true);
  let tempEntities = currentState.entities;
  let tempPeriod = currentState.period ? currentState.period : { periodId: 0 };
  let tempClock = currentState.clock ? JSON.parse(JSON.stringify(currentState.clock)) : {};
  let newClock = null;

  // we did not receive the entities yet --- missing persons message
  if (!tempEntities.length) {
    return;
  }

  tempEntities.forEach((team, index) => {
    tempEntities[index].fouls = 0;
    tempEntities[index].timeouts = 0;
    tempEntities[index].score = 0;
  });

  message = message.sort(function (a, b) {
    var aMixed = new Date(a.eventTime.replace("Z", ""));
    var bMixed = new Date(b.eventTime.replace("Z", ""));
    return aMixed < bMixed || a.periodId < b.periodId ? -1 : 1;
  });

  message.forEach((event, index) => {
    if (Object.keys(scoreTypes).includes(event.eventType) && event.success === true) {
      const eventSubType = scoreSubTypes[event.eventType];

      if (!eventSubType || eventSubType === event.subType) {
        let entityIndex = tempEntities.findIndex((team) => event.entityId === team.entityId);

        if (entityIndex > -1) {
          tempEntities[entityIndex].score = tempEntities[entityIndex].score + scoreTypes[event.eventType];
        }
      }
    }

    if (event.periodId >= tempPeriod.periodId) {
      tempPeriod.periodId = event.periodId;
    }
    switch (event.eventType) {
      case "period":
        tempPeriod.periodId = event.periodId;
        let periodStatus = event.subType === "start" ? "inProgress" : event.subType;
        if (periodStatus !== "pending") {
          // tempPeriod.periodStatus = periodStatus;
          switch (event.subType) {
            case "start":
              tempPeriod.periodStatus = "inProgress";
              break;
            case "end":
              tempPeriod.periodStatus = "periodEnd";
              break;
            case "confirmed":
              if (tempPeriod.periodId >= fixtureProfile.numberOfPeriods) {
                tempPeriod.periodStatus = "pendingExtra";
              } else {
                tempPeriod.periodStatus = "pending";
                tempPeriod.periodId = tempPeriod.periodId + 1;
              }
              break;

            default:
              break;
          }
        }
        // tempPeriod.periodId = event.periodId;
        if (
          event.subType === "pending" &&
          event.periodId > 0
          // &&           event.periodId > tempPeriod.periodId
        ) {
          tempPeriod.periodStatus = "pending";
          tempEntities = checkResets(currentState, event.periodId, fixtureProfile);
          if (!event.clock) {
            if (fixtureProfile.clockDirection === "COUNT_DOWN") {
              event.clock =
                event.periodId >= fixtureProfile.initialExtraPeriodId
                  ? "PT" + fixtureProfile.overtimeLength + "M0S"
                  : "PT" + fixtureProfile.periodLength + "M0S";
            } else {
              event.clock = "PT0M0S";
            }
            tempClock.displayTime = convertClock(event.clock);
          }
        }
        break;
      case "fixture":
        switch (event.subType) {
          case "end":
            tempPeriod.periodStatus = "fixtureConfirm";
            break;
          case "confirmed":
            tempPeriod.periodStatus = "fixtureComplete";
            break;
          case "warm_up":
            tempPeriod.periodStatus = "warm_up";
            break;
          default:
            break;
        }
        break;
      case "main":
        tempClock.displayTime = convertClock(event.clock);
        if (fixtureProfile.clockDirection === "COUNT_DOWN") {
          if (tempClock.displayTime === "00:00" && event.subType === "stop") {
            tempPeriod.periodStatus = "ended";
          }
        } else if (fixtureProfile.clockDirection === "COUNT_UP") {
          if (tempClock.displayTime === `${fixtureProfile.periodLength}:00` && event.subType === "stop") {
            tempPeriod.periodStatus = "ended";
          }
        }
        if (event.subType === "stop") {
          tempClock.clockRunning = false;
          tempClock.catchupStartEvent = null;
        }
        if (event.subType === "start") {
          tempClock.clockRunning = true;
          tempClock.catchupStartEvent = event;
        }
        break;
      case "possession":
        tempEntities.find((team) => team.entityId === event.entityId).hasPossession = true;
        tempEntities.find((team) => team.entityId !== event.entityId).hasPossession = false;
        break;
      case "possessionArrow":
        tempEntities.find((team) => team.entityId === event.entityId).hasArrow = true;
        tempEntities.find((team) => team.entityId !== event.entityId).hasArrow = false;
        break;
      case "foul":
        let ignoreSubTypes = ["drawn", "benchTechnical", "coachDisqualifying", "coachTechnical"];
        if (!ignoreSubTypes.includes(event.subType)) {
          tempEntities.find((team) => team.entityId === event.entityId).fouls++;
        }
        break;
      case "timeOut":
        if (event.entityId) {
          tempEntities.find((team) => team.entityId === event.entityId).timeouts++;
        }
        break;
      case "substitution": {
        const person = tempEntities
          .find((entity) => entity.entityId === event.entityId)
          .persons.find((person) => person.personId === event.personId);

        if (!person) {
          return;
        }

        person.active = event.subType === "in" ? true : false;
        break;
      }
      case "scoreAdjustment":
        Object.keys(event.scores).forEach((entityId) => {
          const currentEntity = tempEntities.find((entity) => entity.entityId === entityId);
          if (currentEntity) {
            currentEntity.score = event.scores[entityId];
          }
        });
        break;
      default:
        break;
    }
    if (event.clock) {
      newClock = convertClock(event.clock);
    }
  });

  updateState("entities", tempEntities);
  updateState("period", tempPeriod);
  if (!currentState.clock && !fromLive) {
    tempClock.displayTime = newClock;
    tempClock.clockRunning = tempClock.clockRunning ? true : false;
    updateState("clock", tempClock);
  }
  // if (!currentState.mqtt.pbpProcessed) {
  eventLogCatchup(message, manageEvents, managePlays, fixtureId);
  // }
  let mqttState = currentState.mqtt;
  mqttState.pbpProcessed = true;
  updateState("mqtt", mqttState);
  updateState("processing", false);
  logger.log("Conn:", "Event Processing Done (ProcessCatchup.js)");
}

function checkResets(currentState, periodId, fixtureProfile) {
  let tempEntities = currentState.entities;
  if (periodId) {
    let lastPeriod = parseInt(periodId) - 1;
    //    Reset Fouls
    if (fixtureProfile.resetFoulsAfterPeriods.includes(lastPeriod)) {
      if (tempEntities[0]) {
        tempEntities[0].fouls = 0;
      }
      if (tempEntities[1]) {
        tempEntities[1].fouls = 0;
      }
    }
    // Reset Timeouts
    if (fixtureProfile.resetTimeoutsAfterPeriods.includes(lastPeriod)) {
      if (tempEntities[0]) {
        tempEntities[0].timeouts = 0;
      }
      if (tempEntities[1]) {
        tempEntities[1].timeouts = 0;
      }
    }
  }
  return tempEntities;
}
