import { createBaseEvent } from "../../../../Components/Base/Utilities/CreateBaseEvent";
import { sendEvent } from "../../../../Components/Base/Utilities/SendEvent";

export function modify7mPenaltyFailureReason(props, failureReason) {
  let changeEvent = { ...props.event };

  changeEvent.status = "updated";
  changeEvent.options["failureReason"] = failureReason;

  let updateAction = {
    action: "sendEvent",
    event: changeEvent,
    type: "custom",
  };
  sendEvent(updateAction, props);
}

export function active7mPenaltyFailureReason(props, editFlow) {
  const { event } = props;

  return Object.keys(editFlow?.options).find((key) => key === event.options["failureReason"]);
}
