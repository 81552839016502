import React, { useContext, useEffect, useState } from "react";
import defaultSetup from "../../Config/defaultSetupConfiguration";
import { FixtureDetailsContext } from "../../Config/FixtureDetails";
import { InactivityTrackerContext } from "../../Config/InactivityTracker";
import { getWorkflowForFixture } from "../../Workflows";
import AspectCheck from "../Base/AspectCheck/AspectCheck";
import BrowserUtilities from "../Base/Utilities/BrowserUtilities";
import { clockCatchup } from "../Base/Utilities/Catchup/ClockCatchup";
import { multiDeviceClockCatchup } from "../Base/Utilities/Catchup/MultiDeviceClockCatchup";
import { processCatchup } from "../Base/Utilities/Catchup/ProcessCatchup";
import { isEntityValidToCompete } from "../Base/Utilities/EntityUtilities";
import Logger from "../Base/Utilities/Logger";
import { managePlayers } from "../Base/Utilities/ManagePlayers";
import QueuedActions from "../Base/Utilities/QueuedActions";
import EventLog from "../Collect/EventLog/EventLog";
import WorkflowBuilder from "../Collect/WorkflowBuilder/WorkflowBuilder";
import { sendPossessionEvent } from "../Base/Utilities/PossessionEvents";
import { eventLogCatchup } from "../Base/Utilities/Catchup/EventLogCatchup";
import FreshChat from "../ChatWidget/FreshChat";
import { withRouter } from "react-router-dom";
import { HoverEventProvider } from "../../Config/HoverEvent";
import { possessionCatchup } from "../Base/Utilities/Catchup/PossessionCatchup";

const logger = Logger.getInstance();

const Game = (props) => {
  const { currentState, updateState, updateLog, debug, history, playStore, eventStore, manageEvents, managePlays } =
    props;
  const { setup, userMode } = currentState;
  const [, updateAllState] = React.useState();
  const forceUpdate = React.useCallback(() => updateAllState({}), []);
  const {
    hasLoaded: hasFixtureConfigLoaded,
    organization: organizationConfig,
    fixtureProfile,
    sport,
  } = useContext(FixtureDetailsContext);
  const [workflow, setWorkflow] = useState(null);
  const { setLastActionTime } = useContext(InactivityTrackerContext);

  const organizationId = organizationConfig?.organizationId;

  const getSortedEvents = (unsortedEvents) => {
    return [...unsortedEvents]
      .map((event) => {
        if (typeof event.eventTime !== "string") {
          event.eventTime = new Date(event.eventTime).toISOString();
        }
        return event;
      })
      .sort((a, b) => (new Date(a.eventTime.replace("Z", "")) > new Date(b.eventTime.replace("Z", "")) ? -1 : 1))
      .reverse();
  };

  useEffect(() => {
    if (organizationId) {
      updateState("orgnizationId", organizationId);
      sessionStorage.setItem("orgId", organizationId);
    }
  }, [organizationId, updateState]);

  useEffect(() => {
    if (organizationId) {
      updateState("orgnizationId", organizationId);
      sessionStorage.setItem("orgId", organizationId);
    }
  }, [organizationId, updateState]);

  useEffect(() => {
    const newWorkflow = getWorkflowForFixture({ sport, userMode });
    setWorkflow(newWorkflow);
  }, [sport, userMode]);

  useEffect(() => {
    if (currentState.mqtt && hasFixtureConfigLoaded) {
      logger.log("INIT :: connected and fixture details loaded", currentState.gettingTeams);
      updateState("gettingTeams", true);

      const storedTeams = JSON.parse(localStorage.getItem(currentState.fixtureId + "-teams"));
      const hasValidTeamData =
        !!storedTeams &&
        Array.isArray(storedTeams) &&
        storedTeams.length > 0 &&
        storedTeams.every((entity) => isEntityValidToCompete(entity, fixtureProfile, sport));
      logger.log("INIT :: valid team data check:", hasValidTeamData);

      // if we don't have valid local team data then lets ask the user to verify everything is correct
      if (!hasValidTeamData) {
        updateState("gettingTeams", false);
        history.push("/details/" + currentState.fixtureId);
      } else {
        if (currentState.catchupsDone) {
          logger.log("INIT :: catchupsDone:", currentState.mqtt.plays);
          if (currentState.mqtt.plays?.length) {
            // this means we have PBP and persons data so it's a fixture in progress
            managePlayers(currentState, currentState.fixtureId, updateState);
            processCatchup(
              currentState.mqtt.plays,
              currentState,
              updateState,
              manageEvents,
              managePlays,
              currentState.fixtureId,
              fixtureProfile,
            );
          } else {
            if (!currentState.period || currentState.period.periodId < 1) {
              // no PBP data but valid team data locally - go into initial state for the game
              managePlayers(currentState, currentState.fixtureId, updateState);
              updateState("period", {
                periodId: 1,
                periodStatus: "initial",
              });
              const mqtt = currentState.mqtt;
              mqtt.pbpProcessed = true;
              updateState("mqtt", mqtt);
            }
          }
          updateState("gettingTeams", false);
        }
      }
    }
    // eslint-disable-next-line
  }, [hasFixtureConfigLoaded, currentState.catchupsDone]);

  useEffect(() => {
    if (currentState?.mqtt?.plays && currentState.catchupsDone) {
      if (currentState.userMode === "clockOperator" && currentState.entities?.length > 0) {
        processCatchup(
          currentState.mqtt.plays,
          currentState,
          updateState,
          manageEvents,
          managePlays,
          currentState.fixtureId,
          fixtureProfile,
        );
      } else if (currentState.userMode === "scoreOperator") {
        eventLogCatchup(currentState.mqtt.plays, manageEvents, managePlays, currentState.fixtureId);
        clockCatchup(currentState.mqtt.plays, currentState, updateState, fixtureProfile);
      } else if (sport === "handball") {
        multiDeviceClockCatchup(currentState.mqtt.plays, currentState, updateState, fixtureProfile, eventStore);
        eventLogCatchup(currentState.mqtt.plays, manageEvents, managePlays, currentState.fixtureId);
        possessionCatchup(currentState.mqtt.plays, currentState, updateState);
      }
    }
    // eslint-disable-next-line
  }, [currentState?.mqtt?.plays, currentState.userMode, currentState.catchupsDone]);

  useEffect(() => {
    if (currentState?.mqtt?.plays && currentState.catchupsDone) {
      if (currentState.userMode === "clockOperator" && currentState.entities?.length > 0) {
        processCatchup(
          currentState.mqtt.plays,
          currentState,
          updateState,
          manageEvents,
          managePlays,
          currentState.fixtureId,
          fixtureProfile,
        );
      }
    }
    // eslint-disable-next-line
  }, [currentState?.mqtt?.plays, currentState.userMode]);

  useEffect(() => {
    if (
      currentState?.entities &&
      currentState.entities.length > 0 &&
      currentState.catchupsDone &&
      hasFixtureConfigLoaded
    ) {
      const { scoreTypes, scoreSubTypes } = defaultSetup;
      const entityIndexes = {};
      const tempEntities = currentState.entities.map((entity, index) => {
        entityIndexes[entity.entityId] = index;
        return {
          ...entity,
          score: 0,
          fouls: 0,
        };
      });
      const currentPeriod = props.currentState?.period?.periodId || 0;
      const mostRecentPeriodToResetFouls =
        fixtureProfile.resetFoulsAfterPeriods
          .sort((a, b) => a - b) // because sometimes backend returns this table in different order
          .filter((p) => p < currentPeriod)
          .at(-1) ?? -1;
      const foulEventSubtypesToSkip = ["drawn", "benchTechnical", "coachDisqualifying", "coachTechnical"];

      getSortedEvents(eventStore).forEach((event) => {
        const isScoreEvent =
          Object.keys(scoreTypes).includes(event.eventType) && event.success === true && event.status !== "deleted";

        if (isScoreEvent) {
          const eventSubType = scoreSubTypes[event.eventType];

          if (!eventSubType || eventSubType === event.subType) {
            tempEntities[entityIndexes[event.entityId]].score += scoreTypes[event.eventType];
          }
        }
        const isFoulEvent =
          event.eventType === "foul" && event.status !== "deleted" && !foulEventSubtypesToSkip.includes(event.subType);
        if (isFoulEvent && event.periodId > mostRecentPeriodToResetFouls) {
          tempEntities[entityIndexes[event.entityId]].fouls += 1;
        }

        if (event.eventType === "scoreAdjustment" || event.notificationType === "score") {
          if (event.eventType === "scoreAdjustment" && event.status === "deleted") {
            return;
          }

          if (event.notificationType === "score") {
            // score notification's id is always equals to the related scoreAdjustment event's id
            const relatedScoreAdjustmentEvent = eventStore.find((oneEvent) => oneEvent.eventId === event.id);
            if (relatedScoreAdjustmentEvent?.status === "deleted") {
              return;
            }
          }

          Object.keys(event.scores).forEach((entityId) => {
            const currentEntity = tempEntities.find((entity) => entity.entityId === entityId);
            if (currentEntity) {
              currentEntity.score = event.scores[entityId];
            }
          });
        }
      });
      updateState("entities", tempEntities);
      updateState("updateLog", Math.random());
      forceUpdate();
    }
    /* eslint-disable */
  }, [
    JSON.stringify(eventStore),
    JSON.stringify(currentState.mqtt),
    setup,
    hasFixtureConfigLoaded,
    currentState.catchupsDone,
  ]);
  /* eslint-enable */

  useEffect(() => {
    setLastActionTime(new Date());

    return () => setLastActionTime(Infinity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLastActionTime]);

  useEffect(() => {
    const handler = (ev) => {
      if (ev.code === "Tab" && sport === "handball" && currentState.entities) {
        currentState.entities.forEach((entity) => {
          if (!entity.hasPossession) {
            sendPossessionEvent(entity.entityId, "hasPossession", props);
          }
        });

        updateState(
          "entities",
          currentState.entities.map((ent) => ({
            ...ent,
            hasPossession: !ent.hasPossession,
          })),
        );
      }
    };

    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [currentState.entities, props, updateState, sport]);

  const shouldShowGameContent = hasFixtureConfigLoaded && currentState.hasInitiallyLoaded && !!workflow;

  return (
    <React.Fragment>
      <HoverEventProvider>
        {shouldShowGameContent && (
          <React.Fragment>
            <div
              className="main"
              style={{
                width: workflow?.displayEventLog ? "82.5%" : "100%",
              }}
            >
              {sport === "handball" && <FreshChat />}
              <WorkflowBuilder
                {...props}
                currentState={currentState}
                updateState={updateState}
                workFlow={workflow}
                managePlays={managePlays}
                playStore={playStore}
                manageEvents={manageEvents}
                eventStore={eventStore}
                updateLog={updateLog}
              />
            </div>
            {workflow.displayEventLog && (
              <div className="side">
                <EventLog
                  {...props}
                  updateLog={updateLog}
                  currentState={currentState}
                  updateState={updateState}
                  workFlow={workflow}
                  playStore={playStore}
                  eventStore={eventStore}
                />
              </div>
            )}
            <QueuedActions {...props} currentState={currentState} updateState={updateState} />
          </React.Fragment>
        )}
        <BrowserUtilities debug={debug} />
        <AspectCheck />
      </HoverEventProvider>
    </React.Fragment>
  );
};

export default withRouter(Game);
