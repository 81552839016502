import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";

import PlayerSelect from "../../../PlayerSelect/PlayerSelect";
import TypeButton from "../../../ButtonPanel/TypeButton/TypeButton";

import { getEventTime, sendEvent } from "../../../../Base/Utilities/SendEvent";
import { sendClockEvent } from "../../../../Base/Utilities/ClockEvents";
import { sendPossessionEvent } from "../../../../Base/Utilities/PossessionEvents";

import "./Jumpball.scss";

const Jumpball = (props) => {
  const { currentState, intl, updateState } = props;
  const { formatMessage } = intl;
  const [selected, setSelected] = useState([null, null]);
  const [selectionUpdated, setSelectionUpdated] = useState(false);
  const [teamPlayers, setTeamPlayers] = useState();
  const [teamSelects, setTeamSelects] = useState();
  const [startClock, setStartClock] = useState(false);
  const [clock, setClock] = useState(currentState.clock.displayTime);

  useEffect(() => {
    setTeamPlayers(
      currentState.entities.map((team, index) => {
        let panel = {
          options: { team: index, onCourtOnly: true },
          showOn: [true],
          layout: "player-select",
        };
        return (
          <PlayerSelect
            key={team.entityId}
            {...props}
            panel={panel}
            update={updateSelected}
            selectedPlayer={selected[index]}
          />
        );
      }),
    );
    setTeamSelects(
      currentState.entities.map((team, index) => {
        let panel = {
          options: { freeText: team.fullNameLatin },
          showOn: [true],
          layout: "team-button",
        };
        return (
          <TypeButton
            {...props}
            key={team.entityId}
            panel={panel}
            team={index}
            style={{
              background: team.primaryColor,
            }}
            onClick={() => _sendEvents(team, index)}
          />
        );
      }),
    );
    // eslint-disable-next-line
  }, [selectionUpdated, props]);

  useEffect(() => {
    if (selectionUpdated) {
      setSelectionUpdated(false);
    }
    // eslint-disable-next-line
  }, [selected, selectionUpdated]);

  useEffect(() => {
    if (startClock) {
      let clock = currentState.clock;
      clock.clockRunning = true;
      setClock(currentState.clock.displayTime);
      updateState("clock", clock);
      if (!props.panel?.options?.isMidgame) {
        sendClockEvent("sport", "fixture", "start", props);
        sendClockEvent("sport", "period", "start", props);
      }
      sendClockEvent("clock", "main", "start", props);
    }
    // eslint-disable-next-line
  }, [startClock]);

  function updateSelected(person, entityId) {
    let newSelected = selected;
    let teamIndex = currentState.entities.findIndex((el) => el.entityId === entityId);

    newSelected[teamIndex] = person.personId;
    setSelected(newSelected);
    setSelectionUpdated(true);
  }

  function _sendEvents(team, index) {
    const isMidgame = !!props.panel.options.isMidgame;
    const event = {};

    const timestamp = new Date();
    event["timestamp"] = timestamp.toISOString();
    event["eventTime"] = getEventTime();
    const clockParts = clock.split(":");
    event["clock"] = "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";

    event["periodId"] = currentState.period.periodId;
    event["playId"] = uuidv1();
    event["eventType"] = "jumpBall";
    if (isMidgame)
      event.options = !!currentState.events["jumpball"]?.options ? { ...currentState.events["jumpball"]?.options } : {};

    const tempEntities = currentState.entities;
    tempEntities.forEach((entity) => {
      event["eventId"] = uuidv1();
      event["entityId"] = entity.entityId;
      if (entity.entityId === team.entityId) {
        event["personId"] = selected[index];
        event["subType"] = "won";
        tempEntities[index].hasPossession = true;
        if (isMidgame) {
          // if midgame jumpball is allowed and this one is from rebound, then send a proper rebound event too
          if (!!props.panel.options.fromRebound) {
            const isDefensive = currentState.events["shot"].entityId !== event.entityId;
            const reboundEvent = {
              eventId: uuidv1(),
              timestamp: event.timestamp,
              eventTime: event.eventTime,
              clock: event.clock,
              periodId: event.periodId,
              playId: event.playId,
              eventType: "rebound",
              subType: isDefensive ? "defensive" : "offensive",
              personId: event.personId,
              entityId: event.entityId,
            };
            sendEvent(
              {
                action: "sendEvent",
                value: "new",
                type: "custom",
                event: reboundEvent,
              },
              props,
            );
          }
        } else {
          tempEntities[index].hasArrow = false;
        }
        sendPossessionEvent(entity.entityId, "hasPossession", props);
      } else {
        const lostIndex = index === 0 ? "1" : 0;
        event["personId"] = selected[lostIndex];
        event["subType"] = "lost";
        tempEntities[lostIndex].hasPossession = false;
        if (!isMidgame) {
          tempEntities[lostIndex].hasArrow = true;
          sendPossessionEvent(entity.entityId, "hasArrow", props);
        }
      }
      const action = {
        action: "sendEvent",
        value: "new",
        type: "custom",
        event: event,
      };
      sendEvent(action, props);
    });
    updateState("entities", tempEntities);
    updateState("primary", null);
  }

  return (
    <React.Fragment>
      <h2 className="align-center">
        {formatMessage({
          id: "jumpball.competitors",
          defaultMessage: "Select Jumpball Competitors",
        })}
      </h2>
      {teamPlayers}
      <div
        className={
          "match-button action center enabled-" + (selected[0] !== null && selected[1] !== null ? "true" : "false")
        }
        onClick={() => setStartClock(true)}
      >
        {props.panel?.options?.isMidgame
          ? formatMessage({
              id: "clock.start.clock",
              defaultMessage: "Start Clock",
            })
          : formatMessage({
              id: "clock.start.fixture",
              defaultMessage: "Start Match",
            })}
      </div>
      <h2 className="align-center">
        {formatMessage({
          id: "jumpball.winner",
          defaultMessage: "Select the team that recovered the jumpball",
        })}
      </h2>
      <div
        className={
          "team-select-buttons enabled-" + (currentState.clock && currentState.clock.clockRunning ? "true" : "false")
        }
      >
        {teamSelects}
      </div>
    </React.Fragment>
  );
};

export default injectIntl(Jumpball);
