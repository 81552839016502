import { sendEvent } from "../../../../Components/Base/Utilities/SendEvent";

export function modifyFreeThrowMade(props, result) {
  const changeTeamScore = (scoreChange) => {
    let tempEntities = props.currentState.entities;
    const team = tempEntities.findIndex((el) => el.entityId === props.event.entityId);

    if (isNaN(tempEntities[team]["score"])) {
      tempEntities[team]["score"] = 0;
    }

    tempEntities[team]["score"] = parseInt(tempEntities[team]["score"]) + scoreChange;
    props.updateState("entities", tempEntities);
  };

  const hasAssist = () => {
    return props.events?.some((ev) => ev.eventType === "assist");
  };

  const allMissed = () => {
    return props.events
      ?.filter((ev) => ev.eventType === "freeThrow")
      .every((ev) => {
        if (ev.eventId === props.event.eventId) {
          return result === "missed";
        }
        return !ev.success;
      });
  };

  const isLastThrow = ["1Of1", "2Of2", "3Of3"].includes(props.event.subType);

  let changeEvent = { ...props.event };

  changeEvent.status = "updated";
  changeEvent.success = result === "made";

  changeTeamScore(result === "made" ? 1 : -1);

  if ((result === "made" && !hasAssist()) || (allMissed() && hasAssist()) || isLastThrow) {
    changeEvent.flagged = true;
  }

  let updateAction = {
    action: "sendEvent",
    event: changeEvent,
    type: "custom",
  };
  sendEvent(updateAction, props);
}

export function activeFreeThrowMade(props) {
  const { event } = props;

  return event.success ? "made" : "missed";
}
