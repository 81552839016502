import React, { useEffect, useState, useRef } from "react";
import { Spinner, Container } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/basicFetch";

import FixtureRow from "./FixtureRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";
import { ENVIRONMENT } from "../../Config/Environment";

import "./Fixtures.scss";

const Fixtures = (props) => {
  const { title, history, intl, clearFixtureSpecificDateFromState } = props;
  const { formatMessage } = intl;
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [fixtureData, setFixtureData] = useState();
  const [queryString, setQueryString] = useState();
  const [authKey, setAuthKey] = useState(null);
  const pageRef = useRef();
  let { error, loading, data } = useFetch(
    "/v1/client/fixtures?" + queryString,
    { method: "GET", values: null, key: authKey },
    refreshFlag,
  );

  const showSport = ["dev", "sandpit"].includes(ENVIRONMENT);

  const columns = [
    formatMessage({
      id: "status",
      defaultMessage: "Status",
    }),
    formatMessage({
      id: "date",
      defaultMessage: "Date",
    }),
    "",
    formatMessage({
      id: "team.home",
      defaultMessage: "Home Team",
    }),
    "",
    formatMessage({
      id: "team.away",
      defaultMessage: "Away Team",
    }),
    formatMessage({
      id: "venue",
      defaultMessage: "Venue",
    }),
    formatMessage({
      id: "season",
      defaultMessage: "Season",
    }),
  ];

  if (showSport) {
    columns.push(
      formatMessage({
        id: "sport",
        defaultMessage: "Sport",
      }),
    );
  }
  // push empty column for selection button
  columns.push("");

  useEffect(() => {
    clearFixtureSpecificDateFromState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var dateFrom = new Date();
    dateFrom.setHours(dateFrom.getHours() - 6);
    var dateTo = new Date();
    dateTo.setHours(dateFrom.getHours() + 144);
    setQueryString("fromUTC=" + dateFrom.toISOString().split(".")[0] + "&toUTC=" + dateTo.toISOString().split(".")[0]);
    setAuthKey(localStorage.getItem("authKey"));
    setRefreshFlag(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      let fixtures = data !== null ? data.data : [];

      fixtures = fixtures.filter((fixture) => !!fixture.venue?.venueId);

      fixtures.forEach((fixture, index) => {
        if (fixture.startTimeLocal) {
          let matchDate = fixture.startTimeLocal.split("T");
          fixtures[index].ISOdate = matchDate[0];
        }
        if (fixture.season) {
          fixtures[index].seasonName = fixture.season.name;
        }
        if (fixture.venue) {
          fixtures[index].venueName = fixture.venue.name;
        }
        if (fixture.competitors) {
          fixture.competitors.forEach((competitor) => {
            if (competitor.isHome) {
              fixtures[index].teamHome = competitor.nameFullLocal;
              fixtures[index].teamHomeLogo = competitor.logo;
            } else {
              fixtures[index].teamAway = competitor.nameFullLocal;
              fixtures[index].teamAwayLogo = competitor.logo;
            }
          });
        }
      });

      if (searchText !== "") {
        fixtures = fixtures.filter((result) => {
          return (
            result.teamHome?.toUpperCase().indexOf(searchText.toUpperCase()) > -1 ||
            result.teamAway?.toUpperCase().indexOf(searchText.toUpperCase()) > -1 ||
            result.venueName?.toUpperCase().indexOf(searchText.toUpperCase()) > -1
          );
        });
      }

      let fixtureList = fixtures
        .sort(function (a, b) {
          var aMixed = a.startTimeLocal;
          var bMixed = b.startTimeLocal;
          return aMixed < bMixed ? -1 : 1;
        })
        .map((result, index) => <FixtureRow key={index} itemIndex={index} result={result} showSport={showSport} />);
      setFixtureData(fixtureList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  return (
    <React.Fragment>
      <Container className="setup-page" fluid={props.fluid}>
        {loading && <Spinner size="md" color="orange" />}
        {!loading && (
          <PageDisplay
            title={formatMessage({
              id: "fixtures",
              defaultMessage: "Fixtures",
            })}
            error={error}
            pageTitle={
              formatMessage({
                id: "fixtures",
                defaultMessage: "Fixtures",
              }) +
              " - " +
              title
            }
            ref={pageRef}
            history={history}
          >
            <Container>
              <SearchFilter doSearch={setSearchText} />
              <TableDisplay containerClass="table-responsive" columns={columns} rows={fixtureData} loading={loading} />
            </Container>
          </PageDisplay>
        )}
      </Container>
    </React.Fragment>
  );
};

export default injectIntl(Fixtures);
