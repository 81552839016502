import { sendEvent } from "../../../../Components/Base/Utilities/SendEvent";

export function modifyGoalAttackType(props, attackType) {
  let changeEvent = { ...props.event };

  changeEvent.status = "updated";
  if (attackType === "none") {
    delete changeEvent.options.attackType;
  } else {
    changeEvent.options["attackType"] = attackType;
  }

  let updateAction = {
    action: "sendEvent",
    event: changeEvent,
    type: "custom",
  };
  sendEvent(updateAction, props);
}

export function activeGoalAttackType(props, editFlow) {
  const { event } = props;

  return Object.keys(editFlow?.options).find((key) => key === event.options["attackType"]);
}
