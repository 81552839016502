let lastProcessedEventId = "";

export async function possessionCatchup(messages, currentState, updateState) {
  let tempEntities = currentState.entities;

  if (!tempEntities.length) {
    return;
  }

  const possessionEvents = messages.filter((ev) => ev.eventType === "possession");

  if (!possessionEvents.length) {
    return;
  }

  const sortedEvents = possessionEvents.sort(function (a, b) {
    var aMixed = new Date(a.eventTime.replace("Z", ""));
    var bMixed = new Date(b.eventTime.replace("Z", ""));
    return aMixed < bMixed || a.periodId < b.periodId ? -1 : 1;
  });

  const lastProcessedMessageIndex = lastProcessedEventId
    ? sortedEvents.findIndex((event) => event.eventId === lastProcessedEventId)
    : 0;
  const messagesToProcess = sortedEvents.slice(lastProcessedMessageIndex + 1);

  if (!messagesToProcess.length) {
    return;
  }

  const lastPossessionEvent = messagesToProcess[messagesToProcess.length - 1];

  lastProcessedEventId = lastPossessionEvent.eventId;
  tempEntities.find((team) => team.entityId === lastPossessionEvent.entityId).hasPossession = true;
  tempEntities.find((team) => team.entityId !== lastPossessionEvent.entityId).hasPossession = false;
  updateState("entities", tempEntities);
}
