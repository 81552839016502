import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { FormattedMessage, injectIntl } from "react-intl";
import { useMultiDeviceClockState } from "./MultiDeviceClockState";
import MultiDeviceClockButtons from "./MultiDeviceClockButtons/MultiDeviceClockButtons";

import { useInterval } from "../../Base/Hooks/Interval";
import { useIsTabActive } from "../../Base/Hooks/isTabActive";
import { sendClockEvent } from "../../Base/Utilities/ClockEvents";
import { createBaseEvent } from "../../Base/Utilities/CreateBaseEvent";
import { convertClock } from "../../Base/Utilities/ConvertClock";
import { differenceInSeconds } from "../../Base/Utilities/Date";
import { sendEvent } from "../../Base/Utilities/SendEvent";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import ClockAdjustSegment from "./ClockAdjustSegment/ClockAdjustSegment";
import MessagePopup from "../../Base/MessagePopup";

import "./MultiDeviceClock.scss";

const MultiDeviceClock = (props) => {
  const { currentState, updateState, panel, intl } = props;
  const { clock, clockCatchup, period, periodCatchup } = currentState;
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [clockRunning, setClockRunning] = useState(false);
  const [editClock, setEditClock] = useState(false);
  const [pressedKey, setPressedKey] = useState(null);
  const [clockState] = useMultiDeviceClockState(props, clockRunning);
  const [isTabActive, tabLastActive, setTabLastActiveTimestamp] = useIsTabActive();
  const [inactivityTimeStart, setInactivityTimeStart] = useState(null);
  const [confirmationPopup, setConfirmationPopup] = useState(null);
  const [catchupState, setCatchupState] = useState({
    startedFromCatchup: false,
    stoppedFromCatchup: false,
  });
  const { fixtureProfile } = useContext(FixtureDetailsContext);
  const { formatMessage } = intl;

  const getDisplayTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds - mins * 60;

    return mins.toString().padStart(2, 0) + ":" + secs.toString().padStart(2, 0);
  };

  const isTimeDisplayVisible = () => {
    return !["waitingForExtraPeriodStart", "shootOutInProgress", "waitingForFixtureEnd", "fixtureComplete"].includes(
      clockState,
    );
  };

  const isTimeEditButtonVisible = () => {
    return ["running", "stopped", "waitingForPeriodEnd"].includes(clockState);
  };

  const localSetClockRunning = (running) => {
    setClockRunning(running);
    if (running) {
      setCatchupState((state) => ({
        ...state,
        startedFromCatchup: false,
      }));
    } else {
      setCatchupState((state) => ({
        ...state,
        stoppedFromCatchup: false,
      }));
    }
  };

  const catchupSetClockRunning = (running) => {
    setClockRunning(running);
    if (running) {
      setCatchupState((state) => ({
        ...state,
        startedFromCatchup: true,
      }));
    } else {
      setCatchupState((state) => ({
        ...state,
        stoppedFromCatchup: true,
      }));
    }
  };

  const getPeriodDisplay = () => {
    if (period?.periodId) {
      if (period.periodId >= fixtureProfile.initialExtraPeriodId) {
        return <FormattedMessage id="period.overtime.shootout" defaultMessage="Shoot-out" />;
      }

      return (
        <FormattedMessage
          id="period.full"
          defaultMessage="Period {period}"
          values={{
            period: period.periodId,
          }}
        />
      );
    }
  };

  const updateConfirmationPopup = (text, onConfirm) => {
    setConfirmationPopup(
      <MessagePopup
        text={text}
        onConfirm={() => {
          onConfirm();
          setConfirmationPopup(null);
        }}
        onDecline={() => setConfirmationPopup(null)}
      />,
    );
  };

  const goToNextPeriod = () => {
    sendClockEvent("clock", "main", "start", props);
    sendClockEvent("clock", "main", "stop", props);
    sendClockEvent("sport", "period", "end", props);
    updateState("period", {
      periodId: period.periodId,
      periodStatus: "periodEnd",
    });
  };

  const confirmPeriod = () => {
    sendClockEvent("sport", "period", "confirmed", props);
    if (period.periodId >= fixtureProfile.numberOfPeriods) {
      updateState("period", {
        periodId: period.periodId,
        periodStatus: "pendingExtra",
      });
    } else {
      const newPeriodId = period.periodId + 1;
      updateState("period", {
        periodId: newPeriodId,
        periodStatus: "pending",
      });
      sendClockEvent("sport", "period", "pending", props);
    }
    setTotalSeconds(0);
  };

  const goToNextExtraPeriod = () => {
    let newPeriod =
      period.periodId === fixtureProfile.numberOfPeriods ? fixtureProfile.initialExtraPeriodId : period.periodId + 1;
    updateState("period", {
      periodId: newPeriod,
      periodStatus: "pending",
    });
    sendClockEvent("sport", "period", "pending", props);
    setTotalSeconds(0);
  };

  const sendClockAdjustment = (seconds) => {
    let newTime = ("PT" + getDisplayTime(seconds) + "S").replace(":", "M");
    sendClockEvent("clock", "main", "adjust", props, "value", newTime);
  };

  const goToShootOutPeriod = () => {
    updateState("period", {
      ...period,
      periodId: fixtureProfile.shootOutPeriodId,
    });
    sendClockEvent("sport", "period", "pending", props);
    sendClockEvent("sport", "period", "start", props);
  };

  const isShootoutPendingEventPresent = () => {
    return !!currentState?.mqtt?.plays.find((event) => event.eventType === "shootOut" && event.subType === "pending");
  };

  const sendShootoutPending = () => {
    const action = createBaseEvent(currentState);

    action.event.eventType = "shootOut";
    action.event.subType = "pending";

    sendEvent(action, props);
  };

  const sendFixtureEvent = (subType) => {
    const action = createBaseEvent(currentState);
    action.event.eventType = "fixture";
    action.event.subType = subType;

    sendEvent(action, props);
  };

  const displayTimeToInt = (displayTime) => {
    return displayTime.split(":").map((v) => parseInt(v, 10));
  };

  const getPeriodMaxSeconds = () => {
    if (!period) {
      return 0;
    }

    if (period.periodId >= fixtureProfile.initialExtraPeriodId) {
      return fixtureProfile.overtimeLength * 60;
    }

    return fixtureProfile.periodLength * 60;
  };

  const clockTick = useCallback(() => {
    if (clockRunning) {
      setTotalSeconds((prevSec) => Math.min(prevSec + 1, getPeriodMaxSeconds()));
    }
  }, [clockRunning, period]);

  const handleTimeEdit = (seconds) => {
    const newSeconds = Math.max(0, Math.min(totalSeconds + seconds, getPeriodMaxSeconds()));
    setTotalSeconds(newSeconds);
    sendClockAdjustment(newSeconds);
  };

  const handleKeyDown = (event) => {
    if (event.code) {
      setPressedKey(event.code);
    }
    event.preventDefault();
  };

  useEffect(() => {
    if (!clock?.displayTime) {
      return;
    }

    if (clock.catchupStartEvent) {
      const deltaTime = Math.floor(
        differenceInSeconds(
          new Date(),
          new Date(`${clock.catchupStartEvent.eventTime}${clock.catchupStartEvent.eventTime.includes("Z") ? "" : "Z"}`),
        ),
      );
      const splitTime = convertClock(clock.catchupStartEvent.clock).split(":");
      const allSeconds = parseInt(splitTime[0], 10) * 60 + parseInt(splitTime[1], 10) + deltaTime;
      setTotalSeconds(Math.max(0, Math.min(allSeconds, getPeriodMaxSeconds())));
    } else {
      const splitTime = clock.displayTime.split(":");
      const allSeconds = parseInt(splitTime[0], 10) * 60 + parseInt(splitTime[1], 10);
      setTotalSeconds(Math.max(0, Math.min(allSeconds, getPeriodMaxSeconds())));
    }
    catchupSetClockRunning(clock.clockRunning);
  }, []);

  useEffect(() => {
    if (clock?.clockStatus === "auto") {
      localSetClockRunning(clock.clockRunning);
    }
  }, [clock?.clockStatus, clock?.clockRunning]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (pressedKey && ["running", "stopped", "waitingForPeriodEnd"].includes(clockState)) {
      switch (pressedKey) {
        case "Space":
          if (["running", "stopped"].includes(clockState)) {
            localSetClockRunning(!clockRunning);
          }
          break;
        case "ArrowUp":
          if (!clockRunning) {
            handleTimeEdit(1);
          }
          break;
        case "ArrowDown":
          if (!clockRunning) {
            handleTimeEdit(-1);
          }
          break;
        case "ArrowRight":
          if (!clockRunning) {
            handleTimeEdit(60);
          }
          break;
        case "ArrowLeft":
          if (!clockRunning) {
            handleTimeEdit(-60);
          }
          break;
        case "KeyC":
          updateState("region", null);
          break;
        default:
          break;
      }
    }
    setPressedKey(null);
  }, [pressedKey]);

  useEffect(() => {
    if (!clockCatchup || !clockCatchup.displayTime) {
      return;
    }

    const [mins, secs] = displayTimeToInt(clockCatchup.displayTime);
    const newTotalSeconds = mins * 60 + secs;
    setTotalSeconds(newTotalSeconds);
    setInactivityTimeStart(newTotalSeconds);
    setTabLastActiveTimestamp();
    catchupSetClockRunning(clockCatchup.clockRunning);
  }, [clockCatchup]);

  useEffect(() => {
    if (periodCatchup) {
      updateState("period", {
        periodId: periodCatchup.periodId ?? 1,
        periodStatus: periodCatchup.periodStatus,
      });
    }
  }, [periodCatchup]);

  useEffect(() => {
    if (
      [
        "running",
        "stopped",
        "waitingForStartMatch",
        "waitingForPeriodEnd",
        "waitingForPeriodStart",
        "waitingForExtraPeriodStart",
      ].includes(clockState)
    ) {
      if (clockRunning && !catchupState.startedFromCatchup) {
        sendClockEvent("clock", "main", "start", props);
      } else if (!clockRunning && !catchupState.stoppedFromCatchup) {
        sendClockEvent("clock", "main", "stop", props);
      }
    }
  }, [clockRunning]);

  useEffect(() => {
    updateState("clock", {
      displayTime: getDisplayTime(totalSeconds),
      clockRunning: clockRunning,
      clockStatus: clockState,
    });
  }, [totalSeconds, clockRunning, clockState]);

  useEffect(() => {
    if (["running", "stopped", "waitingForPeriodEnd"].includes(clockState) && period.periodStatus !== "periodEnd") {
      if (totalSeconds === getPeriodMaxSeconds()) {
        updateState("period", {
          periodId: period?.periodId ?? 1,
          periodStatus: "ended",
        });
        localSetClockRunning(false);
      } else {
        updateState("period", {
          periodId: period?.periodId ?? 1,
          periodStatus: "inProgress",
        });
      }
    }
  }, [totalSeconds, clockState]);

  useEffect(() => {
    if (!clockRunning) {
      return;
    }

    if (isTabActive) {
      if (inactivityTimeStart !== null) {
        const dt = Date.now() - tabLastActive;
        const seconds = inactivityTimeStart + Math.floor(dt / 1000);
        setTotalSeconds(Math.max(0, Math.min(seconds, getPeriodMaxSeconds())));
      }
    } else {
      setInactivityTimeStart(totalSeconds);
    }

    // eslint-disable-next-line
  }, [isTabActive]);

  const buttonEventHandlers = useMemo(() => {
    return {
      startButtonPressed: () => {
        localSetClockRunning(true);
      },
      stopButtonPressed: () => {
        localSetClockRunning(false);
      },
      onPitchButtonPressed: () => {
        sendFixtureEvent("on_pitch");
      },
      endOnPitchButtonPressed: () => {
        updateState("onPitchEnd", true);
      },
      warmUpButtonPressed: () => {
        sendFixtureEvent("warm_up");
        updateState("onPitchEnd", false);
      },
      endWarmUpButtonPressed: () => {
        sendFixtureEvent("about_to_start");
      },
      throwOffButtonPressed: () => {
        updateState("primary", "throw-off");
      },
      startMatchButtonPressed: () => {
        sendClockEvent("sport", "fixture", "start", props);
        sendClockEvent("sport", "period", "start", props);
        updateState("period", {
          periodId: period.periodId,
          periodStatus: "inProgress",
        });
        localSetClockRunning(true);
      },
      endPeriodButtonPressed: () => {
        updateConfirmationPopup(
          formatMessage({
            id: "clock.continue.period.confirmationMessage",
            defaultMessage: "End Period? Are you sure?",
          }),
          goToNextPeriod,
        );
      },
      confirmPeriodButtonPressed: () => {
        confirmPeriod();
      },
      startPeriodButtonPressed: () => {
        updateState("period", {
          periodId: period.periodId,
          periodStatus: "inProgress",
        });
        localSetClockRunning(true);
        sendClockEvent("sport", "period", "start", props);
      },
      nextExtraPeriodButtonPressed: () => {
        updateConfirmationPopup(
          formatMessage({
            id: "clock.continue.period.confirmationMessage",
            defaultMessage: "End Period? Are you sure?",
          }),
          goToNextExtraPeriod,
        );
      },
      shootOutButtonPressed: () => {
        updateConfirmationPopup(
          formatMessage({
            id: "clock.continue.period.confirmationMessage",
            defaultMessage: "End Period? Are you sure?",
          }),
          () => {
            goToShootOutPeriod();
            if (!isShootoutPendingEventPresent()) {
              sendShootoutPending();
            }
            updateState("primary", "shootOut");
          },
        );
      },
      endMatchButtonPressed: () => {
        updateConfirmationPopup(
          formatMessage({
            id: "clock.end.fixture.confirmationMessage",
            defaultMessage: "End Match? Are you sure?",
          }),
          () => {
            sendClockEvent("sport", "fixture", "end", props);
            updateState("period", {
              periodId: period.periodId,
              periodStatus: "fixtureConfirm",
            });
          },
        );
      },
      confirmFixtureButtonPressed: () => {
        sendClockEvent("sport", "fixture", "confirmed", props);
        updateState("period", {
          periodId: period.periodId,
          periodStatus: "fixtureComplete",
        });
      },
    };
  }, [period]);

  useInterval(clockTick, 1000);

  return (
    <>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>
          {confirmationPopup}
          <div className="mdclock-period">{getPeriodDisplay()}</div>
          <div className="mdclock-inner">
            {isTimeEditButtonVisible() && (
              <div className="mdclock-edit">
                {!editClock && <i className="edit-start fas fa-pen-square" onClick={() => setEditClock(true)}></i>}
                {editClock && <i className="edit-done fas fa-check-square" onClick={() => setEditClock(false)}></i>}
              </div>
            )}
            {isTimeEditButtonVisible() && editClock && (
              <div className="mdclock-adjust-segment-left">
                <ClockAdjustSegment onUpPressed={() => handleTimeEdit(60)} onDownPressed={() => handleTimeEdit(-60)} />
              </div>
            )}
            {isTimeEditButtonVisible() && editClock && (
              <div className="mdclock-adjust-segment-right">
                <ClockAdjustSegment onUpPressed={() => handleTimeEdit(1)} onDownPressed={() => handleTimeEdit(-1)} />
              </div>
            )}
            <div className="mdclock-time-buttons">
              {isTimeDisplayVisible() && (
                <div className="mdclock-time">
                  {getDisplayTime(
                    period.periodId >= fixtureProfile.initialExtraPeriodId || period.periodId === 1
                      ? totalSeconds
                      : totalSeconds + fixtureProfile.periodLength * 60,
                  )}
                </div>
              )}
              <div className="mdclock-buttons" style={isTimeDisplayVisible() ? { width: "50%" } : { width: "100%" }}>
                <MultiDeviceClockButtons {...props} {...buttonEventHandlers} clockState={clockState} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(MultiDeviceClock);
