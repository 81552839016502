import { useCallback, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useIsVisible } from "../../../../Base/Hooks/IsVisible";
import Jumpball from "./Jumpball";
import { getEventTimeForUpdatedDeletedEvents, sendEvent } from "../../../../Base/Utilities/SendEvent";

const JumpBallCancelButton_ = (props) => {
  const {
    intl: { formatMessage },
    doCancel,
  } = props;

  const onKeyDown = useCallback(
    (event) => {
      if (event.code === "Escape") {
        doCancel();
      }
    },
    [doCancel],
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <div className="cancel" onClick={doCancel}>
      <span>
        {formatMessage({
          id: "cancel",
          defaultMessage: "cancel",
        })}
      </span>
      <i className="fas fa-times"></i>
    </div>
  );
};

const JumpBallCancelButton = injectIntl(JumpBallCancelButton_);

const InitialJumpBall = (props) => {
  const {
    panel,
    intl: { formatMessage },
    currentState,
    eventStore,
    updateState,
  } = props;
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [initialClockState, setInitialClockState] = useState(null);
  const [initialPeriodState, setInitialPeriodState] = useState(null);

  const _deleteEvent = (event) => {
    if (!event) return;

    event.status = "deleted";
    event.eventTime = getEventTimeForUpdatedDeletedEvents(event);

    const action = {
      action: "sendEvent",
      event: event,
      type: "custom",
    };

    sendEvent(action, props);
  };

  const handleCancel = () => {
    let clock = currentState.clock;
    // clock has started so we need to roll back everything
    if (clock.clockRunning) {
      updateState("clock", initialClockState);
      updateState("period", initialPeriodState);
      const fixtureStartEvent = eventStore.find((event) => event.eventType === "fixture" && event.subType === "start");
      _deleteEvent(fixtureStartEvent);
      const periodStartEvent = eventStore.find((event) => event.eventType === "period" && event.subType === "start");
      _deleteEvent(periodStartEvent);
      const mainStartEvent = eventStore.find((event) => event.eventType === "main" && event.subType === "start");
      _deleteEvent(mainStartEvent);
    }
    updateState("primary", null);
  };

  useEffect(() => {
    const { clock, period } = currentState;
    setInitialClockState(clock);
    setInitialPeriodState(period);
  }, []);

  return (
    <>
      {isVisible && (
        <div className={`${panel.layout} enabled-${isEnabled}`}>
          <div>
            {panel.text && (
              <h3>
                {formatMessage({
                  id: panel.text,
                  defaultMessage: panel.text,
                })}
                :
                <div className="panel-extras">
                  <JumpBallCancelButton doCancel={handleCancel} />
                </div>
              </h3>
            )}
            <div>
              <Jumpball {...props} panel={panel} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(InitialJumpBall);
